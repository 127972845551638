@import '../../../scss/helpers/all.scss';

.default-screen {
  width: 100%;
}

.default-screen__content {
  width: 100%;
}

.default-screen__h2 {
  font-size: size(60, 28);
  line-height: 1.3em;
  @include reg;
}

.default-screen__items {
  margin-top: size(48, 16);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.default-screen__item {
  flex: 0 1 calc(50% - 20px);
  margin-top: size(40, 20);
  display: block;
  &:nth-child(2n + 2) {
    margin-left: 20px;
  }
  @include media('<=1240px') {
    flex: 0 1 100%;
    &:nth-child(2n + 2) {
      margin-left: 0px;
    }
  }
}