@import '../../scss/helpers/all.scss';

.progress-bar {
  position: fixed;
  top: 3vh;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.progress-bar__lines {
  position: relative;
  width: 100%;
  height: 2px;
}

.progress-bar__full-line {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #C4C4C4;
}

.progress-bar__progress-line {
  position: absolute;
  width: 20%;
  height: 100%;
  background-color: white;
  left: 0;
  top: 0;
  transition: width .2s ease;
}

.progress-bar__ui {
  margin-top: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}