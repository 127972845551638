@import '../../scss/helpers/all.scss';

.quiz-nav {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: size(20, 12) 0;
  background-color:  #D3A6EE;
  .e-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.quiz-nav__btn {
  background-color: white;
  color: $dark-purple;
  border: 1px solid transparent;
  width: size(74, 40);
  height: size(74, 40);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    display: block;
    max-width: 100%;
    width: size(24, 12);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.quiz-nav__btn--outline {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  svg {
    transform: rotate(-180deg);
  }
}