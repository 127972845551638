@import "../../scss/helpers/all.scss";

.btn {
  color: $dark-purple;
  border-radius: 3em;
  overflow: hidden;
  position: relative;
  padding: size(18, 14) 78px;
  transition: color .4s ease, background-color .4s ease;
  font-size: size(24, 20);
  &:focus {
    background-color: $dark-purple;
    color: white;
  }
  @include media('<=960px') {
    background: white;
  }
}

.btn__text {
  display: inline-block;
  transition: transform 0.45s cubic-bezier(0.4, 0, 0, 1);
}

.btn__bg {
  background-color: $dark-purple;
  color: white;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 120%);
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  z-index: -1;
  border-radius: 100%;
  opacity: 0.5;
  z-index: 0;
  transition: transform 0.45s cubic-bezier(0.4, 0, 0, 1),
    border-radius 0.45s cubic-bezier(0.4, 0, 0, 1),
    opacity 0.45s cubic-bezier(0.4, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: size(18, 14) 28px;
  @include media('<=960px') {
    display: none;
  }
}

.btn {
  @include media('>=960px') {
    &:hover {
      .btn__text {
        transform: translateY(-150%);
      }
      .btn__bg {
        transform: translate(-50%, 0%);
        border-radius: 0%;
        opacity: 1;
      }
    }
  }
}