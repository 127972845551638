@import "../../../scss/helpers/all.scss";

.final-screen {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 98%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 10;
  overflow: auto;
  @include media('<=1080px') {
    align-items: flex-start;
    padding-top: 60px;
    padding-bottom: 120px;
  }
}

.final-screen__h1 {
  font-size: size(80, 40);
}

.final-screen__content-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-top: size(72, 36);
  @include media('<=1080px') {
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
  }
}

.final-screen__content-left {
  width: 43%;
  @include media('<=1080px') {
    width: 100%;
  }
}

.final-screen__content-right {
  width: 47%;
  @include media('<=1080px') {
    width: 100%;
    margin-top: size(40, 28);
  }
}

.final-screen__small-text {
  margin-top: size(28, 16);
  line-height: 1.5em;
}