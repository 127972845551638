@import "../../scss/helpers/all.scss";

.alert {
  background-color: #CAAE4D;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  right: 4vh;
  top: 10vh;
  z-index: 100;
  padding: 12px size(40, 10);
  border-radius: 12px;
  transform: translateY(60px);
  opacity: 0;
  &.error {
  background-color: #EB5757;
  }
  &.success {
    background-color: #05CB54;
  }
}
