@import "../../scss/helpers/all.scss";

.quiz {
  width: 100%;
  height: 100vh;
}

.screen-wrapper {
  position: relative;
  height: 100%;
  padding-top: 80px;
  padding-bottom: 160px;
  display: flex;
  align-items: center;
  overflow: auto;
  position: absolute;
  top: 0;
  z-index: 10;
  @include media('<=768px') {
    align-items: start;
  }
}