@import './scss/helpers/all.scss';

.app {
  position: relative;
  height: 100vh;
}

.logo {
  svg {
    display: block;
    max-width: 100%;
  }
}

.h1 {
  font-size: size(100, 60);
  line-height: 1em;
  @include medium;
}

.big-text {
  font-size: size(40, 24);
  line-height: 1.4em;
}

.e-container {
  max-width: 1320px;
  overflow: hidden;
  height: 100%;
  position: relative;
  margin: 0 auto;
  @include media('<=1440px') {
    max-width: none;
    width: calc(100% - 200px);
  }
  @include media('<=660px') {
    width: calc(100% - 40px);
  }
}

.bg-gradient {
  position: fixed;
  z-index: 0;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,#707EDA 0%, #AA60E0 100%);
    
    animation: gradientA 10s linear infinite;
  }
}

@keyframes gradientA {
  0% {
      -webkit-filter: hue-rotate(0deg);
      filter: hue-rotate(0deg)
  }

  25% {
      background-position: 0 100%;
  }

  50% {
      background-position: 60% 80%;
      -webkit-filter: hue-rotate(-20deg);
      filter: hue-rotate(-20deg)
  }

  75% {
      background-position: 120% 100%
  }

  to {
      -webkit-filter: hue-rotate(0deg);
      filter: hue-rotate(0deg)
  }
}

.screen {
  visibility: hidden;
  opacity: 0;
  transition: visibility .5s ease, opacity .5s ease;
  &.active {
    visibility: visible;
    opacity: 1;
  }
}