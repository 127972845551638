@import './helpers/all.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: var(--vh);
  color: white;
  @include reg;
  font-size: size(20, 16);
  overflow: hidden;
}

* {
  &::selection {
    background: $dark-purple;
    color: white;
  }
}

.custom {
  * {
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
}
 
