@import "../../../scss/helpers/all.scss";

.hello-screen {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 70%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 10;
  overflow-y: auto;
  @include media('<=1080px') {
    align-items: flex-start;
    padding-top: 60px;
    padding-bottom: 120px;
    width: 98%;
  }
}

.hello-screen__big-text {
  margin-top: size(28, 20);
}

.hello-screen__default-text {
  margin-top: size(40, 28);
  line-height: 1.6em;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    width: 80%;
    + p {
      margin-top: size(20, 16);
    }
    @include media('<=460px') {
      width: 100%;
    }
  }
}

.hello-screen__btn {
  margin-top: size(60, 40);
}
