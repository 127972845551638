@import "../../scss/helpers/all.scss";

.form__input-wrapper {
  position: relative;
  + .form__input-wrapper {
    margin-top: size(28, 20);
  }
}

.form__input {
  width: 100%;
  background: transparent;
  border: white 1px solid;
  border-radius: 3em;
  padding: size(20, 12) size(36, 20);
  transition: border .3s ease;
  position: relative;
  color: white;
  font-size: 20px;
  &.active ~ .form__label {
   visibility: hidden;
   opacity: 0;
  }
  &.focus {
    border: $dark-purple 1px solid;
  }
  &.error {
    border: #EB5757 1px solid;
  }
}

.form__label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: size(36, 20);
  opacity: .7;
  transition: visibility .2s ease, opacity .2s ease;
  pointer-events: none;
  z-index: -1;
}

.form__privacy {
  font-size: 14px;
  display: block;
  margin-top: 18px;
  line-height: 1.4em;
  text-align: end;
}

.form__btn {
  display: block;
  float: right;
  margin-top: size(40, 28);
  padding-left: 28px;
  padding-right: 28px;
  @include media('<=1080px') {
    width: 100%;
  }
}