@mixin cover {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

@mixin coverdiv($pos) {
    position: $pos;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin vcenter($pos) {
    position: $pos;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin center($axis) {
    @if $axis == both {
        @include vcenter;
    } @else if $axis == h {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    } @else if $axis == v {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

@mixin scrollbar {
    &::-webkit-scrollbar {
      @content;
    }
}

@mixin scrollbar-track {
  &::-webkit-scrollbar-track {
    @content;
  }
}

@mixin scrollbar-thumb {
  &::-webkit-scrollbar-thumb {
    @content;
  }
}

@mixin scrollbar-track-piece {
  &::-webkit-scrollbar-track-piece {
    @content;
  }
}

@mixin font($font_name, $file_name, $weight, $style) {
	@font-face {
		font-family: $font_name;
		font-display: swap;
		src: url("../../fonts/#{$file_name}.woff") format("woff"), url("../../fonts/#{$file_name}.woff2") format("woff2");
		font-weight: #{$weight};
		font-style: #{$style};
	}
}
