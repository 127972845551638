@function em($size, $context: 16) {
  @return ($size/$context) * 1em
};

@function img-url($image) {
  @return url('../img/#{$image}')
};

@mixin image($image) {
  background-image: img-url($image)
};

@mixin smooth-scroll {
  -webkit-overflow-scrolling: touch
};

@mixin smooth-font {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

@function size($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - $minWidth;
  @return calc(#{$mobSize + px} + #{$addSize} * ((100vw - #{$minWidth + px}) / #{$maxWidth}))
}