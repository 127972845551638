@import '../../scss/helpers/all.scss';

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding: 10px size(100, 12);
  cursor: pointer;
  user-select: none;
  font-size: size(24, 16);
  line-height: 1.3em;
  text-align: center;
  overflow: hidden;
  border-radius: 3em;
  padding-top: calc(10px + 0.3em);
  &.active .checkmark {
    border: $dark-purple 1px solid;
    background-color: $dark-purple;
  }
  @include media('<=960px') {
    overflow: inital;
    border-radius: 0px;
  }
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: white 1px solid;
  border-radius: 3em;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  z-index: -1;
}

.radio-bg {
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 120%);
  width: 100%;
  height: 100%;
  display: block;
  background-color: $dark-purple;
  border-radius: 100%;
  opacity: 0.5;
  z-index: 0;
  transition: transform 0.45s cubic-bezier(0.4, 0, 0, 1),
    border-radius 0.45s cubic-bezier(0.4, 0, 0, 1),
    opacity 0.45s cubic-bezier(0.4, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px size(100, 12);
  padding-top: calc(10px + 0.3em);
  @include media('<=960px') {
    display: none;
  }
}

.default-screen__item-text {
  display: inline-block;
  transition: transform 0.45s cubic-bezier(0.4, 0, 0, 1);
}

.container {
  @include media('>=960px') {
    &:hover {
      .default-screen__item-text {
        transform: translateY(-150%);
      }
      input ~ .radio-bg {
        transform: translate(-50%, 0%);
        border-radius: 0%;
        opacity: 1;
      }
      input ~ .checkmark {
        border: $dark-purple 1px solid;
      }
    }
  }
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  border: $dark-purple 1px solid;
  background-color: $dark-purple;
}

.container input:focus ~ .checkmark {
  border: $dark-purple 1px solid;
  background-color: $dark-purple;
}
